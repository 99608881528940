import React from 'react';
import styled from "styled-components";
import PriceService from '../../services/PriceService';
import toThousand from '../../services/ToThousandService';
import { useDolarPrice } from '../../hooks/useDolarPrice';

export default function Barton1000Price({ application = null}){

    const [dolar, currentDate] = useDolarPrice()

    const prices = !application ? PriceService.prices : PriceService.prices.filter(prices => prices.application.includes(application))

    return(
        <StylePrices style={{backgroundColor: 'var(--colorFondoMain)'}}>
            <div className="scrollPrice container">
                <h2>Tabla de Precios Barton 1000</h2>
                <div className="contenedorTablaPrecio">
                    <table className="tablaPrecio">
                        <tbody>
                            <tr className="subTituloPrice">
                                <td>Barra</td>
                                <td>Código</td>
                                <td>Peso(KG/M)</td>
                                <td>Presentación</td>
                                <td>Medida</td>
                                <td>Empaque*</td>
                                <td>Peso KG</td>
                                <td>Precio por Metro u$S(Dolar) + IVA</td>
                                <td className="columnaDolar">Precio Empaque U$S(Dolar) + IVA</td>
                                <td className="columnaPesos">Precio $(Pesos) + IVA</td>
                            </tr>
                            {prices.map((price, index)=>{
                                const rowClassName = (price.id % 2 !== 0) ? "filaPriceImpar" : "filaPricePar"; //compara fila par o inpar para aplicar distinta classe
                                return(
                                    <tr key={index} className={rowClassName}>
                                        <td>{price.mod}</td>
                                        <td>{price.cod}</td>
                                        <td>{price.pesoKGM}</td>
                                        <td>{price.pack}</td>
                                        <td>{price.medida} mts</td>
                                        {price.pack === 'Rollo' ? <td>{price.empaque} Rollo</td> : <td>{price.empaque} Barras</td>}
                                        <td>{price.pesoKG}</td>
                                        <td>{price.precioLineal}</td>
                                        <td className="columnaDolar">{toThousand(price.medida * price.empaque * price.precioLineal)}</td>
                                        <td className="columnaPesos">{toThousand(((price.medida * price.empaque * price.precioLineal)*dolar))}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="iconScroll">
                        <i className="fa-solid fa-left-right"></i>
                    </div>
                </div>

                <p className='fletes'><strong>Estos precios no incluyen flete: para Córdoba 4%, Rosario 3%, Santa Fe 3%, Mendoza 6%, Corrientes 6%, Tucumán 8%, Neuquén 8%. Retiro sin cargo en planta Bs. As.</strong></p>
                
                <div className="containerCondiciones container">
                    <p>*Diametro del Rollo: 1mt (B-12 1.3 metros)</p>
                    <p>{currentDate}</p>
                    <p>***Cotización Dolar Oficial Venta Banco Nacion</p>
                </div>
            </div>
        </StylePrices>
    )
}

const StylePrices = styled.div`
    .fletes{
        text-align: center;
        margin-top: 2rem;
        @media (min-width: 768px){
            margin: 0rem;
        }
    }
    .iconScroll{
        display: flex;
        justify-content: center;
        color: var(--colorPrimario);
        @media (min-width: 992px){
            display:none;
        }
    }
    .scrollPrice{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 2rem;
        @media(min-width: 992px){
            display: flex;
            align-items: center;
        }
    }
    .contenedorTablaPrecio::-webkit-scrollbar{
        height: 15px;
        border-radius: 10px;
        background-color: var(--colorSecundario);
    }
    .contenedorTablaPrecio::-webkit-scrollbar-thumb{
        background-color: var(--colorPrimario);
        border-radius: 10px;
        width: 5px;
    }
    .contenedorTablaPrecio{
        overflow-x: scroll;
        flex-direction: column;
        width: 100%;
        @media (min-width: 992px){
            width: 100%;
            display: flex;
            flex-direction: column ;
            justify-content: space-between;
            ::-webkit-scrollbar {display:none;} //Oculta la barra de scroll sin perder la funcion
        }
    }
    h2{
        margin-top: 3.5rem;
    }
    .tablaPrecio{
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        width: 100%;
        border-collapse:collapse;
        margin-right: 10px;
        text-align: center;
        @media (min-width: 768px){
            justify-self: flex-end; /* Alinear tabla a la derecha */
        }
    }
    .containerCondiciones{
        margin-top: 1.5rem;
        font-size: 13px;
    }
    .tituloPrice{
        background-color: var(--colorPrimario);
        margin: 20px;
    }
    .tituloPrice td{
        color: #fff;
        font-family: var(--fuenteSecundaria);
        font-weight: 700;
        font-size: 20px;
        padding: 20px;
    }
    .subTituloPrice{
        background-color: var(--colorGris);
        font-family: var(--fuenteSecundaria);
        color: #fff;
        padding: 5px;
    }
    .subTituloPrice td{
        padding: 10px;
        width: 10%;
        font-weight: 600;
    }
    .filaPricePar {
        background-color: var(--colorFilaTabla);
        font-weight: 500;
    }
    .filaPricePar td{
        padding: 5px;
        font-weight: 500;
    }
    .filaPriceImpar{
        background-color: #fff;
        font-weight: 500;
    }
    .filaPriceImpar td{
        padding: 5px;
        font-weight: 500;
    }
    .columnaDolar{
        background-color: var(--colorTerciario);
        color: #fff;
    }
    .columnaPesos{
        background-color: var(--colorPrimario);
        color: #fff;
    }
    td {
        font-size: 13px;
        @media (min-width: 768px){
            font-size: 15px;
        }
    }
`