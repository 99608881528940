const PriceService = {

    prices: 
        [
            { id: 1, cod: 'BARTON004023', mod: "B-5", pesoKGM:"0.055", pack:'Rollo', medida:100, empaque:1, pesoKG:"5.5", precioLineal:0.2, application: ["Pisos Industriales", "Plateas y Veredas", "Pilotes", "Construcciones ICF", "Piletas", "Premoldeados", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 2, cod: 'BARTON004025', mod: "B-6", pesoKGM:"0.085", pack:'Rollo', medida:100, empaque:1, pesoKG:"8.5", precioLineal:0.29, application: ["Pisos Industriales", "Plateas y Veredas", "Pilotes", "Construcciones ICF", "Piletas", "Premoldeados", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 3, cod: 'BARTON004043', mod: "B-6", pesoKGM:"0.085", pack:'Rollo', medida:200, empaque:1, pesoKG:"17", precioLineal:0.29, application: ["Pisos Industriales", "Plateas y Veredas", "Pilotes", "Construcciones ICF", "Piletas", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 4, cod: 'BARTON004027', mod: "B-8", pesoKGM:"0.140", pack:'Rollo', medida:100, empaque:1, pesoKG:"14", precioLineal:0.47, application: ["Pisos Industriales", "Plateas y Veredas", "Pilotes", "Construcciones ICF", "Piletas", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 5, cod: 'BARTON004030', mod: "B-10", pesoKGM:"0.175", pack:'Rollo', medida:100, empaque:1, pesoKG:"17.5", precioLineal:0.74, application: ["Pisos Industriales", "Plateas y Veredas", "Pilotes", "Construcciones ICF", "Piletas", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 6, cod: 'BARTON004034', mod: "B-12", pesoKGM:"0.250", pack:'Rollo', medida:100, empaque:1, pesoKG:"25", precioLineal:1.06, application: ["Plateas y Veredas", "Pilotes", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 7, cod: 'BARTON004029', mod: "B-8", pesoKGM:"0.140", pack:'Barra', medida:6, empaque:30, pesoKG:"25.2", precioLineal:0.47, application: ["Pisos Industriales", "Plateas y Veredas", "Pilotes", "Construcciones ICF", "Piletas", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 8, cod: 'BARTON004032', mod: "B-10", pesoKGM:"0.175", pack:'Barra', medida:6, empaque:25, pesoKG:"26.2", precioLineal:0.74, application: ["Pisos Industriales", "Plateas y Veredas", "Pilotes", "Construcciones ICF", "Piletas", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 9, cod: 'BARTON004033', mod: "B-12", pesoKGM:"0.250", pack:'Barra', medida:6, empaque:18, pesoKG:"27", precioLineal:1.06, application: ["Plateas y Veredas", "Pilotes", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 10, cod: 'BARTON004036', mod: "B-16", pesoKGM:"0.350", pack:'Barra', medida:6, empaque:10, pesoKG:"21", precioLineal:1.89, application: ["Pilotes", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 11, cod: 'BARTON004038', mod: "B-20", pesoKGM:"0.605", pack:'Barra', medida:6, empaque:7, pesoKG:"25.4", precioLineal:2.96, application: ["Pilotes", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            { id: 12, cod: 'BARTON004040', mod: "B-25", pesoKGM:"0.950", pack:'Barra', medida:6, empaque:4, pesoKG:"22.8", precioLineal:4.62, application: ["Pilotes", "Hidroeléctricas", "Muelles y Puertos", "Obras Eléctricas", "Plantas Industriales"]},
            // { id: 12, mod: "B-32", pesoKGM:"1.02", pack:'Barra', medida:"6 mts", empaque:"4 Barras", pesoKG:"24.5", precioLineal:12.31, precioEmpaque: 295.4},
        ],

    pricesPins:
        [
            { id: 1, mod: "B-20", diametro:"20", largo:'40', empaque:"50 unidades", pesoKG: '11.8', precioLineal:9.65, precioEmpaque: 193},
            { id: 2, mod: "B-20", diametro:"20", largo:'50', empaque:"50 unidades", pesoKG: '14.75', precioLineal:9.65, precioEmpaque: 241.25},
            { id: 3, mod: "B-20", diametro:"20", largo:'60', empaque:"50 unidades", pesoKG: '17.7', precioLineal:9.65, precioEmpaque: 289.5},
            { id: 4, mod: "B-25", diametro:"25", largo:'40', empaque:"50 unidades", pesoKG: '21.2', precioLineal:15.1, precioEmpaque: 302},
            { id: 5, mod: "B-25", diametro:"25", largo:'50', empaque:"40 unidades", pesoKG: '21.2', precioLineal:15.1, precioEmpaque: 377.5},
            { id: 6, mod: "B-25", diametro:"25", largo:'60', empaque:"40 unidades", pesoKG: '25.4', precioLineal:15.1, precioEmpaque: 453},
        ],

    pricesMesh:
        [
            { id: 1, mod: "B-4", diametro:"4", ancho: '1', largo: '6', modulo: '50x50' ,empaque:"Placa", pesoKG: '2.8', precioLineal:5.5, precioMallaCompleta: 33.1, application: ["Premoldeados"]},
            { id: 2, mod: "B-5", diametro:"5", ancho: '1', largo: '6', modulo: '50x50' ,empaque:"Placa", pesoKG: '3.9', precioLineal:6.5, precioMallaCompleta: 39, application: ["Premoldeados"]},
            // { id: 3, mod: "B-6", diametro:"6", ancho: '1', largo: '6', modulo: '50x50' ,empaque:"Placa", pesoKG: '8.4', precioLineal:10.3, precioMallaCompleta: 61.6, application: ["Premoldeados"]},
            { id: 3, mod: "B-4", diametro:"4", ancho: '1', largo: '6', modulo: '100x100' ,empaque:"Placa", pesoKG: '1.4', precioLineal:3.4, precioMallaCompleta: 20.6, application: ["Premoldeados"]},
            { id: 4, mod: "B-5", diametro:"5", ancho: '1', largo: '6', modulo: '100x100' ,empaque:"Placa", pesoKG: '1.9', precioLineal:3.9, precioMallaCompleta: 23.8, application: ["Premoldeados"]},
            // { id: 6, mod: "B-6", diametro:"6", ancho: '1', largo: '6', modulo: '100x100' ,empaque:"Placa", pesoKG: '4.2', precioLineal:5.9, precioMallaCompleta: 35.3, application: ["Premoldeados"]},
            { id: 5, mod: "B-4", diametro:"4", ancho: '1', largo: '6', modulo: '150x150' ,empaque:"Placa", pesoKG: '0.9', precioLineal:2.7, precioMallaCompleta: 16.1, application: ["Plateas y Veredas", "Construcciones ICF", "Premoldeados"]},
            { id: 6, mod: "B-5", diametro:"5", ancho: '1', largo: '6', modulo: '150x150' ,empaque:"Placa", pesoKG: '1.3', precioLineal:3.1, precioMallaCompleta: 16.5, application: ["Plateas y Veredas", "Construcciones ICF", "Premoldeados"]},
            // { id: 9, mod: "B-6", diametro:"6", ancho: '1', largo: '6', modulo: '150x150' ,empaque:"Placa", pesoKG: '2.8', precioLineal:4.4, precioMallaCompleta: 26.6, application: ["Plateas y Veredas", "Construcciones ICF", "Premoldeados"]},
        ],

    
    // pricesSeparator:
    //     [
    //         { id: 1, mod: "B-20", diametro:"20", empaque:"XXX", pesoKG: 'XXX', precioLineal:'XXX', precioEmpaque: 'XXX'},
    //         { id: 2, mod: "B-25", diametro:"25", empaque:"XXX", pesoKG: 'XXX', precioLineal:'XXX', precioEmpaque: 'XXX'},
    //     ]
}

export default PriceService