const toThousand = n => {
    const parts = parseFloat(n).toFixed(2).toString().split('.'); // Asegurar 2 decimales
    const integerPart = parts[0]; 
    const decimalPart = parts[1] || ''; 

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return decimalPart ? `${formattedInteger},${decimalPart}` : formattedInteger;
};

export default toThousand;
